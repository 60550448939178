body, html {
  scroll-behavior: smooth;
  margin: 0;
}

input, select {
  -webkit-appearance: none;
}

button, select {
  text-transform: none;
}

*, :after, :before, html {
  box-sizing: border-box;
}

select {
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  outline: 0;
}

section {
  display: block;
}

html {
  tab-size: 4;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: #0000;
  line-height: 1.15;
  position: relative;
}

*, :after, :before {
  font: inherit;
  outline: #0000;
  margin: 0;
  padding: 0;
}

details > summary, li, ol, ul {
  list-style: none;
}

html:focus-within {
  scroll-behavior: smooth;
}

body {
  text-rendering: optimizespeed;
  line-height: 1.5;
}

a:not([class]) {
  -webkit-text-decoration-skip-ink: auto;
  text-decoration-skip-ink: auto;
}

canvas, img, object, picture, svg, video {
  height: auto;
  max-width: 100%;
  object-fit: cover;
  display: block;
}

h1, h2, h3, h4, h5, h6, p {
  overflow-wrap: break-word;
}

a {
  color: inherit;
  text-decoration: none;
}

b, strong {
  font-weight: 900;
}

select::-ms-expand {
  display: none;
}

address {
  font-style: normal;
}

details summary::-webkit-details-marker {
  display: none;
}

button {
  font-family: inherit;
  font-size: 100%;
  line-height: inherit;
  cursor: pointer;
  border-color: #0000;
  margin: 0;
}

button:disabled {
  cursor: default;
}

[type="button"], [type="reset"], [type="submit"], button {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: ButtonText dotted 1px;
}

:-moz-ui-invalid {
  box-shadow: none;
}

::-webkit-inner-spin-button, ::-webkit-outer-spin-button {
  height: auto;
}

input {
  -webkit-appearance: none;
  appearance: none;
  background-color: #0000;
  border: none;
  border-radius: 0;
}

input::-webkit-search-cancel-button, input::-webkit-search-decoration, input::-webkit-search-results-button, input::-webkit-search-results-decoration {
  display: none;
}

input[type="search"]::-ms-clear {
  width: 0;
  height: 0;
  display: none;
}

input[type="search"]::-ms-reveal {
  width: 0;
  height: 0;
  display: none;
}

input[type="search"]::-webkit-search-cancel-button, input[type="search"]::-webkit-search-decoration, input[type="search"]::-webkit-search-results-button, input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input::-webkit-inner-spin-button, input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

table {
  border-color: inherit;
  text-indent: 0;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

/*# sourceMappingURL=vendor.css.map */
